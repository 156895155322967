import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BaseComponent } from '@app/utils/base/base.component';
import { AreValuesEqualValidator } from '../utils/validators/values-are-equal-validator.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent implements OnInit {

  registrationForm: FormGroup;

  constructor(private fb: FormBuilder, injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.registrationForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(32)]],
      surname: ['', [Validators.required, Validators.maxLength(32)]],
      emailAddress: ['', [Validators.required, Validators.email, Validators.maxLength(126)]],
      userName: ['', [Validators.required, Validators.maxLength(64)]],
      password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(128)]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(128)]]
    },
{      validators: [AreValuesEqualValidator('passwordConfirm', 'password')]}
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.registrationForm.controls; }

  register() {
    console.log(this.registrationForm.controls);
    console.log(this.registrationForm.value);
  }

}
