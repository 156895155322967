import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  authenticate(credentials: {userNameOrEmailAddress: string, password: string}): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/api/TokenAuth/Authenticate`,
      credentials,
      this.getHttpOptions());
  }
}
