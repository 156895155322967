import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@app/utils/base/base.component';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

  authForm = new FormGroup({
    userNameOrEmailAddress: new FormControl('', [Validators.required, Validators.maxLength(124)]),
    password: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(256)])
  });
  constructor(private loginService: LoginService, injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
  }

  public get dd(){
    return this.authForm;
  }
  login() {
    if (this.authForm.valid) {
      this.loginService.authenticate(this.authForm.value).subscribe(e => {
      });
    }
  }
}
