import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalizePipe } from '../pipes/localize.pipe';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private titleSubject$: BehaviorSubject<string>;
  public title$: Observable<string>;

  constructor(private localizePipe: LocalizePipe) {
    const translatedTitle = this.localizePipe.transform('ChooseTicket');
    this.titleSubject$ = new BehaviorSubject(translatedTitle);
    this.title$ = this.titleSubject$.asObservable();
  }

  setTitle(title: string) {
    this.titleSubject$.next(title);
  }

  getTitle$(): Observable<string> {
    return this.titleSubject$.asObservable();
  }
}
