import { Component, OnInit, Injector } from '@angular/core';
import { TitleService } from '../services/title.service';
import { ConfigurationService } from '../services/configuration.service';
import { ConfigurationDto } from '@models/configuration.model';
import { BaseComponent } from '@app/utils/base/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent extends BaseComponent implements OnInit {

  configuration: ConfigurationDto;
  loadingSpinnerShown = true;

  constructor(injector: Injector,
              public titleService: TitleService,
              private router: Router,
              public configurationService: ConfigurationService) {
    super(injector);
  }

  ngOnInit(): void {
    sessionStorage.removeItem('fast-lane');
    this.titleService.setTitle(this.l('SelectTicket'));
    this.configurationService.refreshConfigurationLocalStorage().subscribe(configuration => {
      this.configuration = configuration;

      if (this.configuration.isStartPageEnabled) {
        this.loadingSpinnerShown = false;
      } else {
        if (this.configuration.isTicketBuyingFlowEnabled) {
          if (this.configuration.isOrganizationUnitSelectionPageEnabled) {
            this.router.navigate(['/shop/ou-select']);
          } else {
            this.router.navigate(['/shop/choose']);
          }
        } else {
          this.router.navigate(['/shop/booking-slots']);
        }
      }
    });

  }

  buyTicketClick(): void {
    if (this.configuration.isOrganizationUnitSelectionPageEnabled) {
      this.router.navigate(['/shop/ou-select']);
    } else {
      localStorage.removeItem('organizationUnit');
      this.router.navigate(['/shop/choose']);
    }
  }

  bookingClick(): void {
    this.router.navigate(['/shop/booking-slots']);
  }

  showAllOrdersClick() {
    this.router.navigate(['/shop/previous-orders']);
  }
}
