import { Component, Injector, OnInit } from '@angular/core';
import { LocalizePipe } from '@app/pipes/localize.pipe';
import { TitleService } from '@services/title.service';

@Component({
  selector: 'app-base',
  template: ``,
  styles: [
  ]
})
export class BaseComponent implements OnInit {

  public localizePipe: LocalizePipe;
  public titleService: TitleService;

  constructor(injector: Injector) {
    this.localizePipe = injector.get(LocalizePipe);
    this.titleService = injector.get(TitleService);
  }

  l(text: string): string {
    return this.localizePipe.transform(text);
  }

  addDays(date, days): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  addMinutes(date, minutes): Date {
      return new Date(date.getTime() + minutes * 60000);
  }

  ngOnInit(): void {
  }

}
