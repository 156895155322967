import { AbstractControl, FormGroup } from '@angular/forms';
export function AreValuesEqualValidator(controlName: string, controlNameToCompareTo: string): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const controlToCompareTo = formGroup.controls[controlNameToCompareTo];
        if (control.errors && control.errors?.areValuesEqualValidator == null) {
            // when other validator found and set similar error
            return;
        }

        if (control.value !== controlToCompareTo.value) {
            control.setErrors({areValuesEqualValidator: false});
        } else {
            control.setErrors(null);
        }
    };
}
