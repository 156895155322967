import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ConfigurationDto } from '@models/configuration.model';
import { of, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService extends BaseService {
    constructor(http: HttpClient) {
        super(http);
    }

    getConfigurationFromLocalStorageOrApi(): Observable<ConfigurationDto> {
        const url = this.baseUrl + '/api/mobile/configuration';

        const configuration = JSON.parse(
            localStorage.getItem('MobileShop__Configuration')
        );
        if (configuration) {
            return of(configuration);
        }

        return this.refreshConfigurationLocalStorage();
    }

    getConfigurationFromLocalStorage(): ConfigurationDto {
        const configuration = JSON.parse(
            localStorage.getItem('MobileShop__Configuration')
        );

        return configuration;
    }

    refreshConfigurationLocalStorage(): Observable<ConfigurationDto> {
        const url = this.baseUrl + '/api/mobile/configuration';

        return this.http.get(url, this.getHttpOptions()).pipe<ConfigurationDto>(
            map((data: any) => {
                localStorage.setItem(
                    'MobileShop__Configuration',
                    JSON.stringify(data.body)
                );
                return data.body;
            })
        );
    }
}
