<loading-spinner *ngIf="loadingSpinnerShown"></loading-spinner>

<div *ngIf="!loadingSpinnerShown">
  <div *ngIf="configuration.isPreviousTicketsViewEnabled" (click)="showAllOrdersClick()" class="previous-orders-button">
    <mat-icon class="float-right pointer">bookmarks</mat-icon>
  </div>
  <mat-card *ngIf="configuration.isBookingFlowEnabled" class="start-page-card pointer" (click)="bookingClick()">
    <div><b>{{"IAlreadyHaveTickets"|localize}}</b></div>
    <div>{{"DoYouHaveSubscriptionOrTicket"|localize}}</div>
    <div class="blue">
      {{"BookTimeSlot"|localize}}
    </div>
  </mat-card>
  <mat-card *ngIf="configuration.isTicketBuyingFlowEnabled" class="start-page-card pointer" (click)="buyTicketClick()">
    <div><b>{{"IWantToBuyTicket"|localize}}</b></div>
    <div>
      {{"BuyTicketAndChooseTimeSlot"|localize}}
    </div>
    <div class="blue">
      {{"BuyTicketAndChooseTimeSlot2"|localize}}
    </div>
  </mat-card>
</div>
