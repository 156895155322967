import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { finalize } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header-banner',
  templateUrl: './header-banner.component.html',
  styleUrls: ['./header-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderBannerComponent implements OnInit {
  isLoading = false;
  configurationBannerUrl = '';
  configurationFastLaneBannerUrl = '';
  isFastLane: boolean;
  organizationUnit: string;
  path: string;
  constructor(
    private configurationService: ConfigurationService,
    private angularBrowserTitleService: Title,
    private router: Router,
    private changeDetector: ChangeDetectorRef
      ) { }

  ngOnInit() {
    this.isLoading = true;
    this.path = '/';
    const routerObservable = this.router.events
      .subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          const url = event.urlAfterRedirects;
          if (url === '/'){
            sessionStorage.removeItem('fast-lane');
            this.isFastLane = false;
          }
          if (url.includes('fast-lane')){
            sessionStorage.setItem('fast-lane', JSON.stringify('true'));
            this.isFastLane = true;
          }
          this.organizationUnit = localStorage.getItem('organizationUnit');
          if (this.isFastLane === null){
              this.isFastLane = false;
          }
          if (this.isFastLane && this.organizationUnit !== undefined){
            this.path = '/shop/ou-select/fast-lane/' + this.organizationUnit;

            if (this.isFastLane && this.configurationFastLaneBannerUrl !== undefined){
              this.configurationBannerUrl = this.configurationFastLaneBannerUrl;
            }
          }
          this.isLoading = false;
          this.changeDetector.markForCheck();
      }});
    this.configurationService.refreshConfigurationLocalStorage()
     .pipe(
       finalize(() => {
        this.changeDetector.markForCheck();
      }))
      .subscribe(response => {
        this.configurationBannerUrl = response.bannerUrl;
        this.configurationFastLaneBannerUrl = response.fastLaneBannerUrl;
        if (this.isFastLane && response.fastLaneBannerUrl !== undefined){
          this.configurationBannerUrl = response.fastLaneBannerUrl;
        }
        this.angularBrowserTitleService.setTitle(response.pageTitle);
      });
  }
}
