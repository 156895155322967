import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export abstract class BaseService {
    protected http: HttpClient;
    protected baseUrl = environment.baseUrl;
    constructor(http: HttpClient) {
        this.http = http;
    }

    getHttpOptions(): {
        headers: HttpHeaders;
        observe: any;
        params?: { [param: string]: string | string[] };
        withCredentials?: boolean;
    } {
        const personId = localStorage.getItem('MobileShop__PersonId');
        if (personId === null) {
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }),
                observe: 'response',
            };
        } else {
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    MobileShop__PersonId: personId,
                }),
                observe: 'response',
            };
        }
    }

    saveHeaderInfo(headers) {
        if (localStorage.getItem('MobileShop__PersonId') === null) {
            localStorage.setItem(
                'MobileShop__PersonId',
                headers.get('MobileShop__PersonId')
            );
        }
    }
}
