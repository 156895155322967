import { Platform } from '@angular/cdk/platform';
import { NativeDateAdapter } from '@angular/material/core';
import * as dayjs from 'dayjs';
import 'dayjs/locale/de';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

export class CustomDateAdapter extends NativeDateAdapter {

  constructor(matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);

    // Initalize DayJS-Parser
    dayjs.locale('de');
    dayjs.extend(customParseFormat);
    dayjs.extend(localizedFormat);
  }

  parse(value: any): Date | null {
    return dayjs(value, 'DD.MM.YYYY').toDate();
  }

  format(date: Date, displayFormat: any): string {
    return dayjs(date).format(displayFormat);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
