import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartPageComponent } from './start-page/start-page.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LoginFeatureCanLoadGuard } from './utils/guards/login-feature-can-load.guard';
import { LoginFeatureEnabledAndLoadableGuard } from './utils/guards/login-feature-enabled-and-loadable.guard';


const routes: Routes = [
  {
    path: '',
    component: StartPageComponent,
  },
  {
    path: 'login',
    component: LoginComponent
    , canLoad: [LoginFeatureEnabledAndLoadableGuard]
  },
  {
    path: 'register',
    component: RegisterComponent
    , canLoad: [LoginFeatureEnabledAndLoadableGuard]
  },
  { path: 'shop',
    loadChildren: () => import('./shopping-module/shopping.module').then(m => m.ShoppingModule),
    canLoad: [LoginFeatureCanLoadGuard]
  },
  {
    path: '**',
    component: StartPageComponent
  }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
