import { Component, Injector } from '@angular/core';
import { TitleService } from './services/title.service';
import { BaseComponent } from './utils/base/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {

  constructor(injector: Injector){
    super(injector);
  }
}
