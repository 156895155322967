<div fxLayout="column" fxLayoutAlign="space-between center">
<mat-card class="card-form">
    <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        {{ 'Register' | localize }}
    </h3>
    <form [formGroup]="registrationForm">
        <mat-form-field class="input-form">            
            <input 
            matInput
                formControlName="firstName"
                 type="text"
                autocomplete="new-password" placeholder="{{'FirstName' | localize}} *"
                name="firstName" />
                <mat-error *ngIf="f.firstName.errors?.required">{{"Required" | localize}}</mat-error>

        </mat-form-field>
        <mat-form-field class="input-form">
            <input matInput
                formControlName="surname"
                 type="text"
                autocomplete="new-password" placeholder="{{'Surname' | localize}} *"
                name="surname" />
                <mat-error *ngIf="f.surname.errors?.required">{{"Required" | localize}}</mat-error>
        </mat-form-field>
        <mat-form-field class="input-form">
            <input 
            matInput
                formControlName="emailAddress"
                type="email"
                 type="text"
                autocomplete="new-password" placeholder="{{'Email' | localize}} *"
                name="emailAddress" />
                <mat-error *ngIf="f.emailAddress.errors?.required">{{"Required" | localize}}</mat-error>
                <mat-error *ngIf="f.emailAddress.errors?.email">{{"CorrectEmail" | localize}}</mat-error>
        </mat-form-field>
        <mat-form-field class="input-form">
            <input 
            matInput
                formControlName="userName"
                 type="text"    
                autocomplete="new-password" placeholder="{{'UserName' | localize}} *"
                name="userName" />
                <mat-error *ngIf="f.userName.errors?.required">{{"Required" | localize}}</mat-error>
        </mat-form-field>
        <mat-form-field class="input-form">
            <input 
                matInput
                formControlName="password"
                type="password"
                autocomplete="new-password" placeholder="{{'Password' | localize}} *"
                name="password" />
                <mat-error *ngIf="f.password.errors?.required">{{"Required" | localize}}</mat-error>
        </mat-form-field>
        <mat-form-field class="input-form">
            <input 
                matInput
                formControlName="passwordConfirm"
                type="password"
                autocomplete="new-password" placeholder="{{'PasswordConfirm' | localize}} *"
                name="passwordConfirm" />
            <mat-error *ngIf="f.passwordConfirm?.errors?.areValuesEqualValidator === false">
                {{"PasswordNoMatchError" | localize }}
            </mat-error>
        </mat-form-field>
    </form>
</mat-card>
<button class="next-button" mat-raised-button color="primary" (click)="register()">
    {{"Next"|localize}}
</button>

</div>