<div fxLayout="column" fxLayoutAlign="space-between center">
    <mat-card>
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
            {{ 'LogIn' | localize }}
        </h3>
        <form class="login-form form" [formGroup]="authForm">
            <mat-form-field class="input-form">

                <input matInput formControlName="userNameOrEmailAddress"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="text"
                    autocomplete="new-password" placeholder="{{'UserNameOrEmail' | localize}} *"
                    name="userNameOrEmailAddress" />
            </mat-form-field>

            <mat-form-field class="input-form">

                <input matInput formControlName="password"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="password"
                    autocomplete="new-password" placeholder="{{'Password' | localize}} *" name="password" />
            </mat-form-field>
        </form>
    </mat-card>
    <button class="next-button" mat-raised-button color="primary" [disabled]="authForm.invalid" (click)="login()">
        {{"Next"|localize}}
    </button>
</div>