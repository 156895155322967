import { Pipe, PipeTransform } from '@angular/core';

declare var abp: any;

@Pipe({name: 'localize'})
export class LocalizePipe implements PipeTransform {
  transform(text: string): string {
    return abp?.localization?.values[text] || text;
  }
}
