import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TitleService } from '@services/title.service';

@Component({
  selector: 'app-title-toolbar',
  templateUrl: './title-toolbar.component.html',
  styleUrls: ['./title-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleToolbarComponent implements OnInit {

  constructor(public titleService: TitleService) { }

  ngOnInit(): void {
  }

}
