import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { AgbDialog } from './agb-dialogs/agb-dialog';
import { HeaderBannerComponent } from './header-banner/header-banner.component';
import { StartPageComponent } from './start-page/start-page.component';
import { ErrorDialogComponent } from './error-modals/error-dialog.component';
import localeDe from '@angular/common/locales/de';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UtilsModule } from './utils/utils.module';
import { TitleToolbarComponent } from './title-toolbar/title-toolbar.component';

registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [
    AppComponent,
    ErrorDialogComponent,
    AgbDialog,
    HeaderBannerComponent,
    StartPageComponent,
    LoginComponent,
    RegisterComponent,
    TitleToolbarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    UtilsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  // entryComponents: [TosDialogComponent, AgbDialog, ErrorDialog], <----------- NO NEED Because of IVY
  bootstrap: [AppComponent]
})
export class AppModule { }
